@import "../../styles/variables";
@import "../../styles/breakpoints";

.connect {
  padding: 4rem 1.25rem 6rem 1.25rem;
  background: $off-white;

  @include breakpoint(xlarge) {
    padding: 8rem 0 12rem 0;
  }

  h3 {
    font-size: 3rem;
    font-weight: 200;
    text-align: center;
    text-transform: uppercase;
    margin: 3rem 0;
  }
}

.connectOptions {
  display: grid;
  grid-template-columns: 100%;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  justify-content: center;
  list-style: none;
  margin: 0 auto 3rem auto;
  //max-width: 70%;
  //overflow: hidden;
  padding: 0;

  @include breakpoint(large) {
    grid-template-columns: 1fr 1fr;
    //max-width: 80%;
  }

  @include breakpoint(xlarge) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    max-width: 1280px;
  }

  li {
    display: block;
    position: relative;
  }

  a {
    background: $white;
    color: $white;
    display: block;
    width: 100%;
    height: 100%;
    padding: 2rem 0;
    text-decoration: none;
    text-align: center;
    transition: box-shadow .15s, transform .15s;

    &:hover {
      box-shadow: 2px 2px 3px rgba(0,0,0,.06);
      transform: scale(1.04);
    }

    img {
      display: block;
      height: 60px;
      margin: 0 auto;
      width: 60px;
    }
  }
}
