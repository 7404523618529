@import "../../styles/variables";
@import "../../styles/breakpoints";

.mobileNav {
  background: $white;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  overflow-y: scroll;
  z-index: $mobile-nav-zindex;

  @include breakpoint(medium) {
    display: none;
  }
}

.container {
  padding: .25rem 1.5rem 0 1.5rem;
}

.close {
  cursor: pointer;
  position: absolute;
  top: 1.85rem;
  right: 1.5rem;
}

.brand {
  font-weight: 200;
  font-size: 1.5rem;
  text-transform: uppercase;
  strong {
    font-weight: bold;
  }
  a {
    color: $black;
    text-decoration: none;
  }
}

.options {
  border-top: 1px solid #efefef;
  list-style: none;
  margin: 0;
  padding: 1.5rem 0 0 0;

  li {
    padding: 1rem 0;
    font-size: 2rem;
    text-transform: uppercase;
    a {
      color: $grey;
      text-decoration: none;
    }
  }
}
