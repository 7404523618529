@import "../../styles/breakpoints";

.hero {
  background: #efefef;
  display: grid;
  grid-template-columns: 1fr;
  img {
    width: 100%;
  }
}

.info {
  max-width: 1280px;
  margin: 0 auto 6rem auto;
  padding: 2rem 1.25rem 0 1.25rem;

  @include breakpoint(large){
    padding: 6rem 1.25rem 0 1.25rem;
  }

  ul {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 40px;
    grid-row-gap: 40px;
    list-style: none;
    margin: 0;
    padding: 0;

    @include breakpoint(large) {
      grid-template-columns: 1fr 1fr;
    }

    li {
      padding: 0 2rem;

      h3 {
        font-size: 2rem;
        text-transform: uppercase;
        font-weight: 400;
      }

      p {
        font-size: 1.25rem;
        line-height: 2rem;
        font-weight: 300;
      }

      img {
        display: block;
        margin: 0 auto;
        width: 100%;
      }

      a {
        margin: 1rem 1rem 0 0;
        background: #efefef;
        padding: .75rem 2rem;
        color: #333;
        text-decoration: none;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: .075rem;
        display: inline-block;
      }
    }
  }
}

.highlights {
  max-width: 1280px;
  margin: 0 auto;
  padding: 2rem 1.25rem 0 1.25rem;

  @include breakpoint(large){
    padding: 6rem 1.25rem 0 1.25rem;
  }

  ul {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 40px;
    grid-row-gap: 40px;
    list-style: none;
    margin: 0 auto 4rem auto;
    padding: 0;

    @include breakpoint(large) {
      grid-template-columns: 1fr 1fr;
      margin: 0 auto 12rem auto;
    }

    &:nth-child(odd) {
      direction: rtl;
      text-align: left;
      p {
        direction: ltr;
      }
    }

    li {
      padding: 0 2rem;

      h3 {
        font-size: 2rem;
        text-transform: uppercase;
        font-weight: 400;
      }

      p {
        font-size: 1.25rem;
        line-height: 2rem;
        font-weight: 300;
      }

      img {
        display: block;
        margin: 0 auto;
        width: 100%;
      }
    }
  }
}
