// Color palette
$white:            #ffffff;
$off-white:        #f4f4f4;
$aquablue-light:   #0e9fc2;
$aquablue-overlay: #2c7789;
$aquablue-medium:  #277890;
$aquablue-dark:    #296b7b;
$light-grey:       #CCCCCC;
$grey:             #666666;
$medium-grey:      #2f2f2f;
$dark-grey:        #222222;
$black:            #000000;

$mobile-nav-zindex: 5;
