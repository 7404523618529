@import url('https://fonts.googleapis.com/css?family=Yanone+Kaffeesatz:200,300,400&display=swap');
@import "styles/mixins";
@import "../node_modules/normalize.css";

body {
  font-family: 'Yanone Kaffeesatz', sans-serif;
  font-weight: 200;
  @include fontSmoothing;
}

main {
  padding-top: 4.6rem;
}
