@import "../../styles/variables";
@import "../../styles/mixins";
@import "../../styles/breakpoints";

.projects {
  background: $dark-grey;
  padding: 0 1.25rem 4rem 1.25rem;

  header {
    margin: 0;
    padding: 4rem 0;
    border-bottom: 1px solid $medium-grey;
  }

  h3 {
    font-weight: 200;
    font-size: 3rem;
    color: $white;
    margin: 0;
    text-align: center;
    text-transform: uppercase;
  }

  ul {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 40px;
    grid-row-gap: 40px;
    list-style: none;
    margin: 0 auto;
    //max-width: 80%;
    padding: 5rem 0;

    @include breakpoint(large) {
      grid-template-columns: 1fr 1fr;
    }

    @include breakpoint(xlarge) {
      grid-template-columns: 1fr 1fr 1fr;
      max-width: 1280px;
    }

    a {
      border: 1px solid $medium-grey;
      color: $aquablue-medium;
      padding: 4rem 0;
      text-decoration: none;
      transition: background-color .25s, transform .15s, box-shadow .25s;
      user-select: none;

      &:hover {
        background: lighten($dark-grey, 2%);
        border: 1px solid lighten($medium-grey, 5%);
        box-shadow: 0 5px 15px rgba(0,0,0,.1);
        transform: scale(1.04);
        p {
          color: lighten($aquablue-light, 20%);
        }
      }

      img {
        display: block;
        margin: 0 auto;
      }
    }

    p {
      text-align: center;
      color: $aquablue-light;
      font-size: 1rem;
      font-weight: 300;
      letter-spacing: .02rem;
    }
  }
}
