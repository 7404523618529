@import "../../styles/variables";
@import "../../styles/breakpoints";

.copy {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 30px;
  margin: 0 auto 6rem auto;
  padding: 0 1.25rem;

  @include breakpoint(large) {
    grid-template-columns: 1fr 1fr;
  }

  @include breakpoint(xlarge) {
    max-width: 1280px;
  }

  .copyBlock {
    p {
      font-size: 1.1rem;
      font-weight: 300;
      line-height: 2rem;
    }

    &:last-child {
      p {
        margin-top: 0;
      }
    }

    @include breakpoint(large) {
      &:last-child {
        p {
          margin-top: 1rem;
        }
      }
    }
  }
}
