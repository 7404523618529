@import "../../styles/variables";
@import "../../styles/breakpoints";

.contact {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 30px;
  margin: 0 auto 6rem auto;
  padding: 0 1.25rem;

  @include breakpoint(large) {
    grid-template-columns: 1fr 1fr;
  }

  @include breakpoint(xlarge) {
    max-width: 1280px;
  }

  .contactItem {
    h3 {
      font-size: 1.5rem;
      font-weight: 400;
    }

    p {
      font-size: 1.1rem;
      font-weight: 300;
      line-height: 2rem;
      padding: 0;

      @include breakpoint(large) {
        padding: 0 8rem 0 0;
      }
    }

    a {
      color: $aquablue-medium;
      text-decoration: none;
      &:hover {
        color: $aquablue-light;
      }
    }
  }
}
