@import "../../styles/variables";
@import "../../styles/breakpoints";

.title {
  display: grid;
  grid-template-columns: 1fr;
  margin: 2rem auto 0 auto;
  padding: 0 1.25rem;

  @include breakpoint(xlarge) {
    max-width: 1280px;
  }

  h1 {
    font-size: 4rem;
    font-weight: 300;
    text-transform: uppercase;
    margin-bottom: 0;
  }

  h4 {
    font-size: 1.25rem;
    font-weight: 300;
    margin-top: 0;
    text-transform: uppercase;
  }
}
