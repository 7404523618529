@import "../../styles/variables";
@import "../../styles/mixins";
@import "../../styles/breakpoints";

.hero {
  align-items: center;
  display: grid;
  background: url("/images/hero-bg.jpg");
  background-size: contain;
  grid-template-columns: 1fr;
  height: 80vh;
  justify-items: center;

  .content {
    text-align: center;

    h1 {
      font-size: 3.5rem;
      font-weight: 200;
      position: relative;
      text-transform: uppercase;
      margin: 0;
      padding: 0;

      @include breakpoint(medium) {
        font-size: 5rem;
      }

      @include breakpoint(large) {
        font-size: 6rem;
      }

      span {
        margin-left: 0;

        @include breakpoint(medium) {
          margin-left: 1.5rem;
        }

        @include breakpoint(large) {
          margin-left: 3rem;
        }
      }
    }

    img {
      position: absolute;
      top: -132px;
      left: 45%;
      width: 45px;

      @include breakpoint(medium) {
        top: -86px;
        left: 270px;
        width: 60px;
      }

      @include breakpoint(large) {
        top: -124px;
        left: 329px;
        width: 80px;
      }
    }

    p {
      color: $medium-grey;
      font-size: 1rem;
      font-weight: 400;
      margin: 1rem 0 0;
      text-transform: uppercase;

      @include breakpoint(medium) {
        font-size: 1.25rem;
      }
    }
  }
}
