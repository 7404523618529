/*
*  _mixins
*  Author: Jesse Pinuelas
*  -------------------------------------------------------------------------------------------
*  Notes: Define patterns of property value pairs, which can then be reused in other rule sets
*/

@mixin fontSmoothing() {
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: none;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
}

@mixin fontFamily($font, $style: normal, $weight: normal) {
  font-family: $font, helvetica, arial, sans-serif;
  font-weight: $weight;
  font-style: $style;
}

@mixin center-align {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
