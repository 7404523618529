@import "../../styles/variables";
@import "../../styles/breakpoints";

.navbar {
  background: $white;
  box-shadow: 0 0 4px rgba(0,0,0,.2);
  padding: 1.25rem 0;
  position: fixed;
  width: 100%;
  z-index: 2;

  .navbarContainer {
    display: grid;
    grid-template-columns: auto 1fr;
    margin: 0 auto;
    max-width: 1280px;
    padding: 0 1.25rem;
    position: relative;
  }

  %listContainer {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  %listItem {
    margin-right: 1.5rem;
    a {
      color: $dark-grey;
      text-decoration: none;
      text-transform: uppercase;
      &:hover {
        color: $aquablue-light;
      }
      strong {
        font-weight: bold;
      }
    }
  }

  .navbarBrand {
    @extend %listContainer;
    font-size: 24px;
    justify-self: start;
    padding-top: .45rem;

    li {
      @extend %listItem;
      display: inline-block;
    }
  }

  .navbarItems {
    @extend %listContainer;
    font-size: 18px;
    font-weight: 400;
    justify-self: end;
    padding-top: 0;

    li {
      @extend %listItem;
      display: none;
      letter-spacing: 1px;

      @include breakpoint(medium) {
        display: inline-block;
      }
    }
  }

  .socialItem {
    position: relative;
    top: 4px;

    &:last-child {
      margin-right: 0;
    }

    a {
      display: block;
    }

    img {
      width: 26px;
    }
  }

  .mobileMenuItem {
    background: transparent;
    border: none;
    cursor: pointer;
    display: block;
    height: 26px;
    position: relative;
    top: 8px;
    outline: none;
    img {
      width: 26px;
      height: 26px;
    }
    @include breakpoint(medium) {
      display: none;
    }
  }
}
