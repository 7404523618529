@import "../../styles/variables";
@import "../../styles/breakpoints";

.footer {
  background: $dark-grey;
  padding: 1.25rem;

  p {
    color: $light-grey;
    font-size: 1.1rem;
    font-weight: 300;
    margin: 0 0 .5rem 0;
    text-align: center;

    @include breakpoint(large) {
      text-align: left;
    }

    a {
      color: $aquablue-light;
      text-decoration: none;
      &:hover {
        color: lighten($aquablue-light, 10%);
      }
    }

    span {
      position: relative;
      top: 4px;
    }
  }

  .footerContainer {
    display: grid;
    grid-template-columns: 1fr;
    position: relative;
    margin: 0 auto;
    max-width: 1280px;

    @include breakpoint(large) {
      grid-template-columns: 1fr 1fr;
    }
  }

  .footerItem {
    @include breakpoint(large) {
      &:last-child {
        justify-self: end;
      }
    }
  }
}
