@import url(https://fonts.googleapis.com/css?family=Yanone+Kaffeesatz:200,300,400&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

.navbar_navbar__36XEG {
  background: #ffffff;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  padding: 1.25rem 0;
  position: fixed;
  width: 100%;
  z-index: 2; }
  .navbar_navbar__36XEG .navbar_navbarContainer__1OKZM {
    display: grid;
    grid-template-columns: auto 1fr;
    margin: 0 auto;
    max-width: 1280px;
    padding: 0 1.25rem;
    position: relative; }
  .navbar_navbar__36XEG .navbar_navbarBrand__2wQXL, .navbar_navbar__36XEG .navbar_navbarItems__2OQIO {
    list-style: none;
    margin: 0;
    padding: 0; }
  .navbar_navbar__36XEG .navbar_navbarBrand__2wQXL li, .navbar_navbar__36XEG .navbar_navbarItems__2OQIO li {
    margin-right: 1.5rem; }
    .navbar_navbar__36XEG .navbar_navbarBrand__2wQXL li a, .navbar_navbar__36XEG .navbar_navbarItems__2OQIO li a {
      color: #222222;
      text-decoration: none;
      text-transform: uppercase; }
      .navbar_navbar__36XEG .navbar_navbarBrand__2wQXL li a:hover, .navbar_navbar__36XEG .navbar_navbarItems__2OQIO li a:hover {
        color: #0e9fc2; }
      .navbar_navbar__36XEG .navbar_navbarBrand__2wQXL li a strong, .navbar_navbar__36XEG .navbar_navbarItems__2OQIO li a strong {
        font-weight: bold; }
  .navbar_navbar__36XEG .navbar_navbarBrand__2wQXL {
    font-size: 24px;
    justify-self: start;
    padding-top: .45rem; }
    .navbar_navbar__36XEG .navbar_navbarBrand__2wQXL li {
      display: inline-block; }
  .navbar_navbar__36XEG .navbar_navbarItems__2OQIO {
    font-size: 18px;
    font-weight: 400;
    justify-self: end;
    padding-top: 0; }
    .navbar_navbar__36XEG .navbar_navbarItems__2OQIO li {
      display: none;
      letter-spacing: 1px; }
      @media (min-width: 640px) {
        .navbar_navbar__36XEG .navbar_navbarItems__2OQIO li {
          display: inline-block; } }
  .navbar_navbar__36XEG .navbar_socialItem__C4HLQ {
    position: relative;
    top: 4px; }
    .navbar_navbar__36XEG .navbar_socialItem__C4HLQ:last-child {
      margin-right: 0; }
    .navbar_navbar__36XEG .navbar_socialItem__C4HLQ a {
      display: block; }
    .navbar_navbar__36XEG .navbar_socialItem__C4HLQ img {
      width: 26px; }
  .navbar_navbar__36XEG .navbar_mobileMenuItem__s3rXU {
    background: transparent;
    border: none;
    cursor: pointer;
    display: block;
    height: 26px;
    position: relative;
    top: 8px;
    outline: none; }
    .navbar_navbar__36XEG .navbar_mobileMenuItem__s3rXU img {
      width: 26px;
      height: 26px; }
    @media (min-width: 640px) {
      .navbar_navbar__36XEG .navbar_mobileMenuItem__s3rXU {
        display: none; } }

.mobile-nav_mobileNav__1jo8q {
  background: #ffffff;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  overflow-y: scroll;
  z-index: 5; }
  @media (min-width: 640px) {
    .mobile-nav_mobileNav__1jo8q {
      display: none; } }

.mobile-nav_container__mbc6q {
  padding: .25rem 1.5rem 0 1.5rem; }

.mobile-nav_close__1E7E3 {
  cursor: pointer;
  position: absolute;
  top: 1.85rem;
  right: 1.5rem; }

.mobile-nav_brand__136Ob {
  font-weight: 200;
  font-size: 1.5rem;
  text-transform: uppercase; }
  .mobile-nav_brand__136Ob strong {
    font-weight: bold; }
  .mobile-nav_brand__136Ob a {
    color: #000000;
    text-decoration: none; }

.mobile-nav_options__1mQev {
  border-top: 1px solid #efefef;
  list-style: none;
  margin: 0;
  padding: 1.5rem 0 0 0; }
  .mobile-nav_options__1mQev li {
    padding: 1rem 0;
    font-size: 2rem;
    text-transform: uppercase; }
    .mobile-nav_options__1mQev li a {
      color: #666666;
      text-decoration: none; }

/*
*  _mixins
*  Author: Jesse Pinuelas
*  -------------------------------------------------------------------------------------------
*  Notes: Define patterns of property value pairs, which can then be reused in other rule sets
*/
.hero_hero__1924p {
  align-items: center;
  display: grid;
  background: url("/images/hero-bg.jpg");
  background-size: contain;
  grid-template-columns: 1fr;
  height: 80vh;
  justify-items: center; }
  .hero_hero__1924p .hero_content__21Raa {
    text-align: center; }
    .hero_hero__1924p .hero_content__21Raa h1 {
      font-size: 3.5rem;
      font-weight: 200;
      position: relative;
      text-transform: uppercase;
      margin: 0;
      padding: 0; }
      @media (min-width: 640px) {
        .hero_hero__1924p .hero_content__21Raa h1 {
          font-size: 5rem; } }
      @media (min-width: 768px) {
        .hero_hero__1924p .hero_content__21Raa h1 {
          font-size: 6rem; } }
      .hero_hero__1924p .hero_content__21Raa h1 span {
        margin-left: 0; }
        @media (min-width: 640px) {
          .hero_hero__1924p .hero_content__21Raa h1 span {
            margin-left: 1.5rem; } }
        @media (min-width: 768px) {
          .hero_hero__1924p .hero_content__21Raa h1 span {
            margin-left: 3rem; } }
    .hero_hero__1924p .hero_content__21Raa img {
      position: absolute;
      top: -132px;
      left: 45%;
      width: 45px; }
      @media (min-width: 640px) {
        .hero_hero__1924p .hero_content__21Raa img {
          top: -86px;
          left: 270px;
          width: 60px; } }
      @media (min-width: 768px) {
        .hero_hero__1924p .hero_content__21Raa img {
          top: -124px;
          left: 329px;
          width: 80px; } }
    .hero_hero__1924p .hero_content__21Raa p {
      color: #2f2f2f;
      font-size: 1rem;
      font-weight: 400;
      margin: 1rem 0 0;
      text-transform: uppercase; }
      @media (min-width: 640px) {
        .hero_hero__1924p .hero_content__21Raa p {
          font-size: 1.25rem; } }

/*
*  _mixins
*  Author: Jesse Pinuelas
*  -------------------------------------------------------------------------------------------
*  Notes: Define patterns of property value pairs, which can then be reused in other rule sets
*/
@-webkit-keyframes showcase_modal-video__fzk52 {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
@keyframes showcase_modal-video__fzk52 {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes showcase_modal-video-inner__2XNYq {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

@keyframes showcase_modal-video-inner__2XNYq {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

.showcase_modal-video__fzk52 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: showcase_modal-video__fzk52;
          animation-name: showcase_modal-video__fzk52;
  -webkit-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out; }

.showcase_modal-video-effect-exit__3_4wi {
  opacity: 0; }
  .showcase_modal-video-effect-exit__3_4wi .showcase_modal-video-movie-wrap__1_NCH {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.showcase_modal-video-body__Ww1P4 {
  max-width: 940px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: table; }

.showcase_modal-video-inner__2XNYq {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  height: 100%; }

.showcase_modal-video-movie-wrap__1_NCH {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: showcase_modal-video-inner__2XNYq;
          animation-name: showcase_modal-video-inner__2XNYq;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; }
  .showcase_modal-video-movie-wrap__1_NCH iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.showcase_modal-video-close-btn__Mxki8 {
  position: absolute;
  z-index: 2;
  top: -35px;
  right: -35px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  .showcase_modal-video-close-btn__Mxki8:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .showcase_modal-video-close-btn__Mxki8:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .showcase_modal-video-close-btn__Mxki8:before, .showcase_modal-video-close-btn__Mxki8:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

.showcase_showcase__2F50A {
  background: #222222;
  padding: 0 1.25rem 4rem 1.25rem; }
  .showcase_showcase__2F50A header {
    margin: 0;
    padding: 4rem 0;
    border-bottom: 1px solid #2f2f2f; }
  .showcase_showcase__2F50A h3 {
    font-weight: 200;
    font-size: 3rem;
    color: #ffffff;
    margin: 0;
    text-align: center;
    text-transform: uppercase; }
  .showcase_showcase__2F50A ul {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 40px;
    grid-row-gap: 40px;
    list-style: none;
    margin: 0 auto;
    padding: 5rem 0; }
    @media (min-width: 768px) {
      .showcase_showcase__2F50A ul {
        grid-template-columns: 1fr 1fr; } }
    @media (min-width: 1280px) {
      .showcase_showcase__2F50A ul {
        grid-template-columns: 1fr 1fr 1fr;
        max-width: 1280px; } }
    .showcase_showcase__2F50A ul a {
      border: 1px solid #2f2f2f;
      color: #277890;
      padding: 4rem 0;
      text-decoration: none;
      -webkit-transition: background-color .25s, box-shadow .25s, -webkit-transform .15s;
      transition: background-color .25s, box-shadow .25s, -webkit-transform .15s;
      transition: background-color .25s, transform .15s, box-shadow .25s;
      transition: background-color .25s, transform .15s, box-shadow .25s, -webkit-transform .15s;
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none; }
      .showcase_showcase__2F50A ul a:hover {
        background: #272727;
        border: 1px solid #3c3c3c;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
        -webkit-transform: scale(1.04);
                transform: scale(1.04); }
        .showcase_showcase__2F50A ul a:hover p {
          color: #44d0f2; }
      .showcase_showcase__2F50A ul a img {
        display: block;
        margin: 0 auto; }
    .showcase_showcase__2F50A ul p {
      text-align: center;
      color: #0e9fc2;
      font-size: 1rem;
      font-weight: 300;
      letter-spacing: .02rem; }

.connect_connect__2BSea {
  padding: 4rem 1.25rem 6rem 1.25rem;
  background: #f4f4f4; }
  @media (min-width: 1280px) {
    .connect_connect__2BSea {
      padding: 8rem 0 12rem 0; } }
  .connect_connect__2BSea h3 {
    font-size: 3rem;
    font-weight: 200;
    text-align: center;
    text-transform: uppercase;
    margin: 3rem 0; }

.connect_connectOptions__fh3eH {
  display: grid;
  grid-template-columns: 100%;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  justify-content: center;
  list-style: none;
  margin: 0 auto 3rem auto;
  padding: 0; }
  @media (min-width: 768px) {
    .connect_connectOptions__fh3eH {
      grid-template-columns: 1fr 1fr; } }
  @media (min-width: 1280px) {
    .connect_connectOptions__fh3eH {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      max-width: 1280px; } }
  .connect_connectOptions__fh3eH li {
    display: block;
    position: relative; }
  .connect_connectOptions__fh3eH a {
    background: #ffffff;
    color: #ffffff;
    display: block;
    width: 100%;
    height: 100%;
    padding: 2rem 0;
    text-decoration: none;
    text-align: center;
    -webkit-transition: box-shadow .15s, -webkit-transform .15s;
    transition: box-shadow .15s, -webkit-transform .15s;
    transition: box-shadow .15s, transform .15s;
    transition: box-shadow .15s, transform .15s, -webkit-transform .15s; }
    .connect_connectOptions__fh3eH a:hover {
      box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.06);
      -webkit-transform: scale(1.04);
              transform: scale(1.04); }
    .connect_connectOptions__fh3eH a img {
      display: block;
      height: 60px;
      margin: 0 auto;
      width: 60px; }

/*
*  _mixins
*  Author: Jesse Pinuelas
*  -------------------------------------------------------------------------------------------
*  Notes: Define patterns of property value pairs, which can then be reused in other rule sets
*/
.projects_projects__2et3E {
  background: #222222;
  padding: 0 1.25rem 4rem 1.25rem; }
  .projects_projects__2et3E header {
    margin: 0;
    padding: 4rem 0;
    border-bottom: 1px solid #2f2f2f; }
  .projects_projects__2et3E h3 {
    font-weight: 200;
    font-size: 3rem;
    color: #ffffff;
    margin: 0;
    text-align: center;
    text-transform: uppercase; }
  .projects_projects__2et3E ul {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 40px;
    grid-row-gap: 40px;
    list-style: none;
    margin: 0 auto;
    padding: 5rem 0; }
    @media (min-width: 768px) {
      .projects_projects__2et3E ul {
        grid-template-columns: 1fr 1fr; } }
    @media (min-width: 1280px) {
      .projects_projects__2et3E ul {
        grid-template-columns: 1fr 1fr 1fr;
        max-width: 1280px; } }
    .projects_projects__2et3E ul a {
      border: 1px solid #2f2f2f;
      color: #277890;
      padding: 4rem 0;
      text-decoration: none;
      -webkit-transition: background-color .25s, box-shadow .25s, -webkit-transform .15s;
      transition: background-color .25s, box-shadow .25s, -webkit-transform .15s;
      transition: background-color .25s, transform .15s, box-shadow .25s;
      transition: background-color .25s, transform .15s, box-shadow .25s, -webkit-transform .15s;
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none; }
      .projects_projects__2et3E ul a:hover {
        background: #272727;
        border: 1px solid #3c3c3c;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
        -webkit-transform: scale(1.04);
                transform: scale(1.04); }
        .projects_projects__2et3E ul a:hover p {
          color: #44d0f2; }
      .projects_projects__2et3E ul a img {
        display: block;
        margin: 0 auto; }
    .projects_projects__2et3E ul p {
      text-align: center;
      color: #0e9fc2;
      font-size: 1rem;
      font-weight: 300;
      letter-spacing: .02rem; }

.workdetail_hero__27GyX {
  background: #efefef;
  display: grid;
  grid-template-columns: 1fr; }
  .workdetail_hero__27GyX img {
    width: 100%; }

.workdetail_info__j7sQj {
  max-width: 1280px;
  margin: 0 auto 6rem auto;
  padding: 2rem 1.25rem 0 1.25rem; }
  @media (min-width: 768px) {
    .workdetail_info__j7sQj {
      padding: 6rem 1.25rem 0 1.25rem; } }
  .workdetail_info__j7sQj ul {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 40px;
    grid-row-gap: 40px;
    list-style: none;
    margin: 0;
    padding: 0; }
    @media (min-width: 768px) {
      .workdetail_info__j7sQj ul {
        grid-template-columns: 1fr 1fr; } }
    .workdetail_info__j7sQj ul li {
      padding: 0 2rem; }
      .workdetail_info__j7sQj ul li h3 {
        font-size: 2rem;
        text-transform: uppercase;
        font-weight: 400; }
      .workdetail_info__j7sQj ul li p {
        font-size: 1.25rem;
        line-height: 2rem;
        font-weight: 300; }
      .workdetail_info__j7sQj ul li img {
        display: block;
        margin: 0 auto;
        width: 100%; }
      .workdetail_info__j7sQj ul li a {
        margin: 1rem 1rem 0 0;
        background: #efefef;
        padding: .75rem 2rem;
        color: #333;
        text-decoration: none;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: .075rem;
        display: inline-block; }

.workdetail_highlights__3Oh8Q {
  max-width: 1280px;
  margin: 0 auto;
  padding: 2rem 1.25rem 0 1.25rem; }
  @media (min-width: 768px) {
    .workdetail_highlights__3Oh8Q {
      padding: 6rem 1.25rem 0 1.25rem; } }
  .workdetail_highlights__3Oh8Q ul {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 40px;
    grid-row-gap: 40px;
    list-style: none;
    margin: 0 auto 4rem auto;
    padding: 0; }
    @media (min-width: 768px) {
      .workdetail_highlights__3Oh8Q ul {
        grid-template-columns: 1fr 1fr;
        margin: 0 auto 12rem auto; } }
    .workdetail_highlights__3Oh8Q ul:nth-child(odd) {
      direction: rtl;
      text-align: left; }
      .workdetail_highlights__3Oh8Q ul:nth-child(odd) p {
        direction: ltr; }
    .workdetail_highlights__3Oh8Q ul li {
      padding: 0 2rem; }
      .workdetail_highlights__3Oh8Q ul li h3 {
        font-size: 2rem;
        text-transform: uppercase;
        font-weight: 400; }
      .workdetail_highlights__3Oh8Q ul li p {
        font-size: 1.25rem;
        line-height: 2rem;
        font-weight: 300; }
      .workdetail_highlights__3Oh8Q ul li img {
        display: block;
        margin: 0 auto;
        width: 100%; }

.title_title__1NJAt {
  display: grid;
  grid-template-columns: 1fr;
  margin: 2rem auto 0 auto;
  padding: 0 1.25rem; }
  @media (min-width: 1280px) {
    .title_title__1NJAt {
      max-width: 1280px; } }
  .title_title__1NJAt h1 {
    font-size: 4rem;
    font-weight: 300;
    text-transform: uppercase;
    margin-bottom: 0; }
  .title_title__1NJAt h4 {
    font-size: 1.25rem;
    font-weight: 300;
    margin-top: 0;
    text-transform: uppercase; }

.contact_contact__DRYFE {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 30px;
  margin: 0 auto 6rem auto;
  padding: 0 1.25rem; }
  @media (min-width: 768px) {
    .contact_contact__DRYFE {
      grid-template-columns: 1fr 1fr; } }
  @media (min-width: 1280px) {
    .contact_contact__DRYFE {
      max-width: 1280px; } }
  .contact_contact__DRYFE .contact_contactItem__1U8oi h3 {
    font-size: 1.5rem;
    font-weight: 400; }
  .contact_contact__DRYFE .contact_contactItem__1U8oi p {
    font-size: 1.1rem;
    font-weight: 300;
    line-height: 2rem;
    padding: 0; }
    @media (min-width: 768px) {
      .contact_contact__DRYFE .contact_contactItem__1U8oi p {
        padding: 0 8rem 0 0; } }
  .contact_contact__DRYFE .contact_contactItem__1U8oi a {
    color: #277890;
    text-decoration: none; }
    .contact_contact__DRYFE .contact_contactItem__1U8oi a:hover {
      color: #0e9fc2; }

.copy_copy__Xt5hl {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 30px;
  margin: 0 auto 6rem auto;
  padding: 0 1.25rem; }
  @media (min-width: 768px) {
    .copy_copy__Xt5hl {
      grid-template-columns: 1fr 1fr; } }
  @media (min-width: 1280px) {
    .copy_copy__Xt5hl {
      max-width: 1280px; } }
  .copy_copy__Xt5hl .copy_copyBlock__33-aF p {
    font-size: 1.1rem;
    font-weight: 300;
    line-height: 2rem; }
  .copy_copy__Xt5hl .copy_copyBlock__33-aF:last-child p {
    margin-top: 0; }
  @media (min-width: 768px) {
    .copy_copy__Xt5hl .copy_copyBlock__33-aF:last-child p {
      margin-top: 1rem; } }

.footer_footer__EQtlF {
  background: #222222;
  padding: 1.25rem; }
  .footer_footer__EQtlF p {
    color: #CCCCCC;
    font-size: 1.1rem;
    font-weight: 300;
    margin: 0 0 .5rem 0;
    text-align: center; }
    @media (min-width: 768px) {
      .footer_footer__EQtlF p {
        text-align: left; } }
    .footer_footer__EQtlF p a {
      color: #0e9fc2;
      text-decoration: none; }
      .footer_footer__EQtlF p a:hover {
        color: #15c4ee; }
    .footer_footer__EQtlF p span {
      position: relative;
      top: 4px; }
  .footer_footer__EQtlF .footer_footerContainer__AaFZD {
    display: grid;
    grid-template-columns: 1fr;
    position: relative;
    margin: 0 auto;
    max-width: 1280px; }
    @media (min-width: 768px) {
      .footer_footer__EQtlF .footer_footerContainer__AaFZD {
        grid-template-columns: 1fr 1fr; } }
  @media (min-width: 768px) {
    .footer_footer__EQtlF .footer_footerItem__2rwhz:last-child {
      justify-self: end; } }

/*
*  _mixins
*  Author: Jesse Pinuelas
*  -------------------------------------------------------------------------------------------
*  Notes: Define patterns of property value pairs, which can then be reused in other rule sets
*/
body {
  font-family: 'Yanone Kaffeesatz', sans-serif;
  font-weight: 200;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: none;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased; }

main {
  padding-top: 4.6rem; }

